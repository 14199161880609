import { Injectable } from '@angular/core';

import {
  BsAuthService,
  BsHubService, UserAttributesInterface
} from "@brightside-web/desktop/data-access/core-services";
import * as Sentry from '@sentry/angular-ivy';

import {
  HubCapsule,
  HubPayload,
  MessageBusEventChannel,
  MessageBusIncomingEventKey,
  MessageBusInternalEventKey,
  MessageBusInternalService,
} from '@micro-core/message-bus';
import { MicroUtilDebugLogger } from '@micro-core/utility';

import { AmplitudeService, FirebaseService } from '@brightside-web/desktop/data-access/shared';

import { InjectedInformation } from '../models/Injected-data.interface';
import {FeatureFlagService} from "@brightside-web/desktop/data-access/core-services";
import {Environment} from "@micro-core/environment";
import {of} from "rxjs";

declare global {
  interface Window {
    Injected: InjectedInformation;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService extends MicroUtilDebugLogger {
  private _authToken: string;
  userAttributes: UserAttributesInterface;

  static checkSessionValidity(expireAt: string): boolean {
    if (!expireAt) return false;

    return true;
  }

  constructor(
    private amplitudeService: AmplitudeService,
    protected analytics: FirebaseService,
    private featureFlagService: FeatureFlagService,
    private bsAuthService: BsAuthService,
    private bsHubService: BsHubService,
    private env: Environment,
    private featureFlagSvc: FeatureFlagService,
    ) {
    super();


    const listener = (data: HubCapsule<any, any>) => {
      switch (data.payload.event) {
        case 'signedIn':
          this.bsAuthService.fetchUserAttributes().subscribe(
            attributes => {
              this.userAttributes = attributes;
              this.bsHubService.dispatch('AuthChannel', {event: 'logged in', data: attributes});
              this.setFirebaseProperties(attributes as unknown as { [key:string]:string });
              this.setAmplitudeProperties(attributes as unknown as { [key:string]:string });
              Sentry.setUser({id:attributes['guid']});
              this.featureFlagSvc.initializeFeatureFlag(attributes);
              setTimeout(()=>{this.broadcastAuthChange(attributes);}, 100)
            }
          );
          break;
      }
    };

    this.bsHubService.listen('bsAuth', listener);

    this.allowVerboseDebugMode = true;
    this.logPrefix = 'AuthorizationService - ';

    this.logForDebugging('AuthorizationService Creating');

    if (!window.Injected) {
      window.Injected = {};
    }

    this.startHubListenerForNewAuthToken();
    this.startInitAuthorize();
    // this.checkCurrentAuthenticatedUser();
  }

  setFirebaseProperties(properties: { [key:string]:string }) {
    this.analytics.setUserId(properties['guid']);
    const passThrough = {
      company: properties['company']
    }
    this.analytics.setProperties(passThrough);

    this.analytics.setupRemoteConfig();
  }

  setAmplitudeProperties(properties: { [key:string]:string }) {
    this.amplitudeService.setUserId(properties['guid']);
    const passThrough = {
      company: properties['company']
    }
    this.amplitudeService.setUserProperties(passThrough);
  }

  get injectedData(): InjectedInformation {
    if (!(window as any).Injected) return { authToken: '', version: '9.9.9' };

    return window.Injected;
  }


  /**
   * Will look to the client's token first to fine locale and fall back
   * to anything passed inside Injected otherwise.
   *
   * This checks for: userAttributes.locale - Fallback to window.Injected?.locale
   */
  get userLocale(): string {
    let localeFromAuth = '';
    if (this.userAttributes && this.userAttributes.locale) {
      localeFromAuth = this.userAttributes.locale;
    }
    const returnLocale = localeFromAuth || window.Injected?.locale || '';

    return returnLocale;
  }


  get authToken(): string {
    return window.Injected?.authToken || '';
  }

  get accessToken(): string {
    return window.Injected?.token?.accessToken || '';
  }

  get idToken(): string {
    return window.Injected?.token?.idToken || '';
  }

  get refreshToken(): string {
    return window.Injected?.token?.refreshToken || '';
  }

  /**
   * Sends a internal hub event MessageBusInternalEventKey.AUTH_UPDATED
   */
  private broadcastAuthChange(user: unknown) {
    MessageBusInternalService.sendInternalHubEvent({
      event: MessageBusInternalEventKey.AUTH_UPDATED,
      data: { user },
    });
  }

  private startHubListenerForNewAuthToken() {
    this.logForDebugging(`Hub Started for events (${MessageBusIncomingEventKey.AUTH})`);

    MessageBusInternalService.addHubListenerWithEventFilter({
      channel: MessageBusEventChannel.INCOMING,
      filterByEvents: [MessageBusIncomingEventKey.AUTH],
      callbackListener: (payload: HubPayload) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const authToken = payload.data?.body?.value as string;

        this.logForDebugging(`${MessageBusIncomingEventKey.AUTH} event hit payload`, payload);

        if (window.Injected && authToken) {
          window.Injected.authToken = authToken;
        }


      },
    });
  }


  private setUserInfoToAmplitude(guid: string, userProperties: { [key: string]: string }) {
    const passThrough = {
      company: userProperties['custom:company'],
      guid,
    };

    this.logForDebugging(`Set up amplitude with`, passThrough);

    this.amplitudeService.setUserId(guid);
    this.amplitudeService.setUserProperties(passThrough);
  }

  private setUserInfoToAnalytics(guid: string, userProperties: { [key: string]: string }) {
    const passThrough = {
      company: userProperties['custom:company'],
      guid,
    };

    this.logForDebugging(`Set up analytics with`, passThrough);

    this.analytics.setUserId(guid);
    this.analytics.setProperties(passThrough);
  }

  private startInitAuthorize() {
    this.logForDebugging(`Kicked off init authorize check`);
    console.log(`ken here, window.Injected ${JSON.stringify(window.Injected)}`);
    this.tryToAuthorizeWithToken();
  }

  private tryToAuthorizeWithToken(): boolean {
    if (!this.accessToken) {
      this.logForDebugging(`Trying to authorize with token but missing token`);

      return false;
    }
    this.bsAuthService.setAccessTokenForMicroApps(this.accessToken);

    return true;
  }


}
