import {inject, Injectable} from '@angular/core';

import {AuthService as Auth0Service, User} from '@auth0/auth0-angular';
import {forkJoin, Observable, of, shareReplay, Subject, switchMap, throwError} from 'rxjs';
import {catchError, map, take, tap} from "rxjs/operators";
import {BsHubService} from "../Hub/bs-hub.service";
import {
  ProfileService
} from "@brightside-web/desktop/data-access/shared";
import {SimpleGlobalModalService} from "../GlobalModal/simple-global-modal.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Environment} from "@micro-core/environment";

export interface UserAttributesInterface {
  guid: string;
  intercom_external_id: string;
  last_name: string;
  first_name: string;
  phone_number: string;
  email: string;
  locale: string;
  company: string;
  sf_contact_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class BsAuthService {

  private _bsHubService: BsHubService = inject(BsHubService);
  private _auth0Service: Auth0Service = inject(Auth0Service);
  private _profileService: ProfileService = inject(ProfileService);
  private _modalSvc: SimpleGlobalModalService = inject(SimpleGlobalModalService);

  private _userLoggedInSubject = new Subject<void>();
  private _userLoggedOutSubject = new Subject<void>();

  private storedAccessToken: string;
  private overrideIsAuthenticated: boolean | null = null;

  constructor(private http: HttpClient, private env: Environment) {
    this.isAuthenticated().subscribe(authenticated => {
      if (authenticated) {
        this.notifyUserLoggedIn();
      } else {
        this.notifyUserLoggedOut();
      }
    });
    this._auth0Service.error$.subscribe(
      errors => {
        // in some cases there can still be user information available
        // to the Auth0 Angular SDK, which results in the user being
        // authenticated, so we need to override
        this.overrideIsAuthenticated = false;
        // we can override to not redirect as well if we want
        this.logout().subscribe();
      }
    )
  }

  getUserLoggedInEvent(): Observable<void> {
    return this._userLoggedInSubject.asObservable();
  }

  getUserLoggedOutEvent(): Observable<void> {
    return this._userLoggedOutSubject.asObservable();
  }

  notifyUserLoggedIn() {
    this._userLoggedInSubject.next();
    this._bsHubService.dispatch('bsAuth', { event: 'signedIn' });
  }

  notifyUserLoggedOut() {
    this._userLoggedOutSubject.next();
    this._bsHubService.dispatch('bsAuth', { event: 'signedOut' });
  }

  login(prompt: 'login' | 'signup' | string = 'login'): void {
    this.overrideIsAuthenticated = null;
    this._auth0Service.loginWithRedirect({
      authorizationParams: {
          screen_hint: prompt,
        }
      }
    );
  }

  logout(): Observable<void> {

    const logoutOptions = {
      logoutParams: {
        returnTo: window.location.origin
      }
    };

    return new Observable<void>((observer) => {
      const logoutSubscription = this._auth0Service.logout(logoutOptions).subscribe({
        next: () => {
          this.overrideIsAuthenticated = null;
          observer.next();
          observer.complete();
        },
        error: (error: any) => {
          observer.error(error);
          console.error('Error logging out', error);
        }
      });
      return () => {
        logoutSubscription.unsubscribe();
      };
    });
  }

  isAuthenticated(): Observable<boolean> {
    if (typeof this.overrideIsAuthenticated === 'boolean') {
      return of(this.overrideIsAuthenticated);
    }
    return this._auth0Service.isAuthenticated$;
  }

  getToken(): Observable<string> {
    return this.isAuthenticated().pipe(
      switchMap(authenticated => {
        if (authenticated) {
          if (this.storedAccessToken) return of(this.storedAccessToken);
          return this._auth0Service.getAccessTokenSilently({
              authorizationParams: {
                scope: 'openid profile email phone_number read:current_user read:current_user_metadata offline_access'
              }
            }
          ).pipe(
            map(token => token ?? ''),
            catchError(error => {
              console.error('Error retrieving token:', error);
              return of('');
            })
          );
        } else {
          return of('');
        }
      })
    );
  }

  getIdTokenClaims() {
    return this._auth0Service.idTokenClaims$;
  }

  fetchUserAttributes(force = false): Observable<UserAttributesInterface> {
    return this._profileService.getProfile(force);
  }

  setAccessTokenForMicroApps(accessToken: string) {
    this.storedAccessToken = accessToken;
    this.overrideIsAuthenticated = true;
    this.notifyUserLoggedIn();
  }
}
