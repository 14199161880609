import { Injectable } from '@angular/core';
import { from, Observable, of, Subject } from 'rxjs';
import {
  BsAuthService,
  BsCacheService,
  BsHubService,
  BsLoggerService,
} from '@brightside-web/desktop/data-access/core-services';
import { ConsoleLogger } from 'aws-amplify/utils'
import { catchError, map } from 'rxjs/operators';
import { FirebaseService } from './firebase.service';
import { HubCapsule } from 'aws-amplify/utils';

import { AmplitudeService } from "./amplitude.service";
import * as Sentry from '@sentry/angular-ivy';
import {FeatureFlagService} from "@brightside-web/desktop/data-access/core-services";
import {Router} from "@angular/router";
declare const Sprig:Function;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userLoggedIn = new Subject<boolean>();
  redirectUrl: string;
  logger: ConsoleLogger;
  username: string | undefined;
  password: string | undefined;
  company: string | undefined;

  setRedirectUrl(path: string) {
    this.redirectUrl = path;
  }

  constructor(
    private analytics: FirebaseService,
    private amplitudeService: AmplitudeService,
    private featureFlagSvc: FeatureFlagService,
    private bsAuthService: BsAuthService,
    private bsCacheService: BsCacheService,
    private bsHubService: BsHubService,
    private bsLoggerService: BsLoggerService,
    private router: Router) {
    this.bsAuthService.fetchUserAttributes().subscribe({
      next: (attributes) => {
        const userAtts = attributes;
        this.featureFlagSvc.initializeFeatureFlag(userAtts);
        this.userLoggedIn.next(true)
      },
      error: (err) => this.userLoggedIn.next(false)
    });

    this.logger = new ConsoleLogger('My-Logger');
    const listener = (data: HubCapsule<any, any>) => {
      switch (data.payload.event) {
        case 'signedIn':

          this.logger.info('user signed in');

          this.bsAuthService.fetchUserAttributes().subscribe(
            attributes => {
              this.bsHubService.dispatch('AuthChannel', {event: 'logged in', data: attributes});
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.handleSignIn(attributes['guid']);
              this.setFirebaseProperties(attributes as unknown as { [key:string]:string });
              this.setAmplitudeProperties(attributes as unknown as { [key:string]:string });
              this.setUserLoggedIn(true);
              Sentry.setUser({id:attributes['guid']});
              this.featureFlagSvc.initializeFeatureFlag(attributes);
              if (window.location.href.includes('/authenticating')) {
                this.router.navigate(['home']);
                setTimeout(()=> {
                  // sometimes angular isn't redirecting the first time
                  if (window.location.href.includes('/authenticating')) {
                    this.router.navigate(['home']);
                  }
                }, 1000)
              }
            }
          )
          break;
        case 'signUp':
          this.logger.info('user signed up');
          break;
        case 'oAuthSignOut':
        case 'signedOut':
          if ( typeof Sprig !== 'undefined' ) {
            Sprig('logoutUser');
          }
          this.bsCacheService.removeItem("GUID");
          this.logger.info(`user ${data.payload.event}` as any);
          this.analytics.clearProperties();
          this.amplitudeService.clearProperties();
          this.setUserLoggedIn(false);
          Sentry.setUser(null);
          this.bsHubService.dispatch('AuthChannel', {event: 'logged out'});
          this.featureFlagSvc.disableClient();
          break;
        case 'signIn_failure':
          this.logger.info('user sign in failed');
          break;
        case 'configured':
          this.logger.info('the Auth module is configured');
          break;
        default:
          this.logger.info(`unhandled Auth event: ${data.payload.event}`);
      }
    };

    this.bsHubService.listen('bsAuth', listener);
  }



  private handleSignIn(guid: string) {
    this.bsCacheService.setItem("GUID",guid);

    const now = Date.now();

    const previousSignIn = window.localStorage[guid];

    this.bsCacheService.getItem("SIGN_IN_TIME").then(
      signInTime => {
        if (!previousSignIn || previousSignIn === signInTime) {
          window.localStorage[guid] = now;
          this.bsCacheService.setItem("SIGN_IN_TIME", now);
          this.bsCacheService.setItem("FIRST_SIGN_IN",true);
        }
        else {
          this.bsCacheService.setItem("FIRST_SIGN_IN",false);
        }
      }
    )

  }

  /**
   * want to set more than just guid.
   * @param properties
   */
  setFirebaseProperties(properties: { [key:string]:string }) {
    this.analytics.setUserId(properties['custom:guid']);
    const passThrough = {
      company: properties['custom:company']
    }
    this.analytics.setProperties(passThrough);

    this.analytics.setupRemoteConfig();
  }

  setAmplitudeProperties(properties: { [key:string]:string }) {
    this.amplitudeService.setUserId(properties['custom:guid']);
    const passThrough = {
      company: properties['custom:company']
    }
    this.amplitudeService.setUserProperties(passThrough);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  hasCurrentUser(): Observable<boolean> {
    return this.bsAuthService.isAuthenticated();
  }

  clearConfirmationState() {
    this.username = undefined;
    this.password = undefined;
  }
}
